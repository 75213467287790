@font-face {
  font-family: 'Fantasque Sans Mono';
  src: url(../fonts/fantasque/FantasqueSansMono-Bold.woff2) format('woff2'),
    url('../fonts/fantasque/FantasqueSansMono-Bold.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fantasque Sans Mono';
  src: url('../fonts/fantasque/FantasqueSansMono-BoldItalic.woff2') format('woff2'),
    url('../fonts/fantasque/FantasqueSansMono-BoldItalic.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Fantasque Sans Mono';
  src: url('../fonts/fantasque/FantasqueSansMono-Italic.woff2') format('woff2'),
    url('../fonts/fantasque/FantasqueSansMono-Italic.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Fantasque Sans Mono';
  src: url('../fonts/fantasque/FantasqueSansMono-Regular.woff2') format('woff2'),
    url('../fonts/fantasque/FantasqueSansMono-Regular.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Andika';
  src: url('../fonts/andika/Andika-Regular.woff2') format('woff2'),
    url('../fonts/andika/Andika-Regular.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Andika';
  src: url('../fonts/andika/Andika-Italic.woff2') format('woff2'),
    url('../fonts/andika/Andika-Italic.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Andika';
  src: url('../fonts/andika/Andika-Bold.woff2') format('woff2'),
    url('../fonts/andika/Andika-Bold.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Andika';
  src: url('../fonts/andika/Andika-BoldItalic.woff2') format('woff2'),
    url('../fonts/andika/Andika-BoldItalic.woff') format('woff');
  /* Firefox >= 3.6, any other modern browser */
  font-weight: 700;
  font-style: italic;
}

body {
    background-image: url(../fonts/fantasque/FantasqueSansMono-Bold.woff2);
}
//@font-face {
//  font-family: 'SH Pinscher';
//  src: url('../fonts/shpinscher/SHPinscher-Regular.woff') format('woff'); /* Firefox >= 3.6, any other modern browser */
//  font-weight: 400;
//  font-style: normal;
//}
/*
@font-face {
  font-family: 'Monoid';
  src: url('../fonts/monoid/Monoid-Regular-NoCalt.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Monoid';
  src: url('../fonts/monoid/Monoid-Italic-NoCalt.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Monoid';
  src: url('../fonts/monoid/Monoid-Bold-NoCalt.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
*/
//@font-face {
//	font-family: "DeluxeFont";
//	src: url("../fonts/deluxefont/DeluxefontRegular.woff") format("woff");
//	font-weight: normal;
//	font-style: normal;
//	/* font-size-adjust: 0.63 */
//}
/*
@font-face {
    font-family: "PressStart2P";
    src: url("../fonts/pressstart2p/PressStart2P.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
*/
@font-face {
  font-family: "PressStart2P Narrow";
  src: url("../fonts/pressstart2p/PressStart2P-Narrow.woff2") format("woff2"),
    url("../fonts/pressstart2p/PressStart2P-Narrow.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  size-adjust: 90%;
  ascent-override: 135%;
  descent-override: 5%;
  //line-gap-override: 90%;
}

/*
@font-face {
    font-family: "PressStart2P Tall";
    src: url("../fonts/pressstart2p/PressStart2P-Tall.woff2") format("woff2"),
		 url("../fonts/pressstart2p/PressStart2P-Tall.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
*/
//@font-face {
//    font-family: "Glasstown-NBP";
//    src: url("../fonts/glasstown/Glasstown-NBP.woff") format("woff");
//    font-weight: normal;
//    font-style: normal;
//}
@font-face {
  font-family: "TeenyTinyPixls";
  src: url("../fonts/teenytinypixls/TeenyTinyPixls.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}