@use "sass:color";
@import "./fonts.scss";

* {
    box-sizing: border-box;
    ;
}

$color-light: #F6F3E9;
$color-1: #E95C20;
$color-2: #E9C120;
$color-3: #cf3c3a;
$color-dark: #3D3D3D;

:root {
    --color-1: #{$color-1};
    --color-1-light: #{color.scale($color-1, $lightness:+40%)};
    --color-1-dark: #{color.scale($color-1, $lightness:-60%)};
    //--color-1b: #F36E31;
    --color-2: #{$color-2}; //--color-2b: #Ecbf36;
    --color-2-desat: #{color.scale($color-2, $saturation:-40%)}; 
    --color-2-light: #{color.scale($color-2, $lightness:+40%)};
    --color-2-light-desat: #{color.scale($color-2, $lightness:+40%, $saturation:-40%)};
    --color-3: #{$color-3};
    --color-3-light: #{color.scale($color-3, $lightness:+40%)};
    //--color-3b: #E92049;
    --color-dark: #{$color-dark};
    --color-light: #{$color-light};

    --resizer-bg: #{color.scale($color-light, $lightness:-10%)};
    --resizer-bg-hover: #{color.scale($color-1, $lightness:+30%)};

    --text-bg: var(--color-light);
    --text-fg: var(--color-dark);

    --error-fg: var(--color-3);
    --warn-fg: var(--color-2);

    --frame-text: #333;
    --frame-text-faded: #666;
    --frame-border-focused: #{color.scale($color-2, $lightness:+20%)};
    --frame-header: #{color.scale($color-light, $lightness:+20%)};
    --frame-header-focused: #{color.scale($color-2, $lightness:+40%)};
    --frame-tab: #{color.scale($color-light, $lightness:-10%)};
    --frame-tab-selected: #{color.scale($color-light, $lightness:+10%)};
    --frame-tab-focused: #{color.scale($color-2, $lightness:+20%)};
    --frame-tab-focused-selected: #{color.scale($color-2, $lightness:+60%)};
}

@mixin pixel-font {
    font-family: "PressStart2P Narrow", "Fantasque Sans Mono", "Monoid", monospace;
    // font-size-adjust: .71428;
}

@mixin hires-font {
    //font-family: "Glasstown-NBP", sans-serif;
    //font-family: "Fantasque Sans Mono", "Monoid", monospace;
    //      font-size-adjust: 0.66; 
    //letter-spacing: -0.06em;
}

@mixin normal-font {
    font-family: "Andika", serif;
}

@mixin mono-font {
    font-family: "Fantasque Sans Mono", "Monoid", monospace;
    //font-size-adjust: .6;
}

@mixin tiny-font {
    font-family: "TeenyTinyPixls", sans-serif;
}
/*
@media (prefers-reduced-motion: no-preference) {

    *,
    ::before,
    ::after {
        animation-duration: 0.001s !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.001s !important;
    }
}
*/
body {
    background-color: var(--color-light);
    background-image: radial-gradient(#fff3 0%, #0003 100%);
    background-attachment: fixed;
    margin: 0;
}

body,
button,
input,
select,
option,
span.no-retro {
    @include normal-font;
}

body.retro,
body.retro button,
body.retro input,
body.retro select,
body.retro option {
    @include pixel-font;
}

#page {
    //padding: .5rem;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

label {
    justify-self: end;
}

#mid {
    display: grid;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 1rem .5rem;
}

h1 {
    font-variant: small-caps;
    font-weight: normal;
}

#debugger {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content 1fr max-content;
    grid-gap: .5rem;
    background: #f5f5f5;
    box-shadow: 3px 3px 3px #3333;
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
    margin-bottom: .25rem;

    .buttons {
        text-align: center;
        padding: .25rem;

        button {
            border-width: .25rem;
            font-size: 1.2rem;
            margin: .25rem;
            border-radius: .5rem;
        }

        textarea {
            resize: vertical;
        }

        label {
            font-weight: bold;
            margin-right: .5rem;
        }
    }
}


input:invalid {
    border-color: var(--color-3)
}

button, a.button {
    border: .125rem outset var(--color-2);
    border-radius: .25rem;
    background-color: var(--color-2-light);

    .buttons & {
        border: .125rem outset var(--color-2);
        border-radius: .25rem;
    }

    &:disabled {
        border-style: solid;
        background-color: var(--color-2-light-desat);
        border-color: var(--color-2-desat);
    }
}

button:hover:not(:disabled),
button:active:not(:disabled), a.button:hover, a.button:active {
    border-color: var(--color-1);
    background-color: var(--color-1-light);
    border-style:outset;
}

button:hover:disabled {
    border-color: var(--color-2-light-desat);
}

button:active:not(:disabled) {
    border-style: inset;
}

button.running {
    border-style: solid !important;
    border-color: var(--color-1-light);
    cursor: wait;
}

a:link.external,
a:link[target="_blank"] {
    position: relative;
}
a:link {
    color: var(--color-1-dark);
}
a.external, a[target="_blank"], a[download] {
    color: #00e;
}
a:visited.external a[target="_blank"]:visited, a[download]:visited {
    color: #632d94
}
a:link.external::after,
a:link[target="_blank"]::after {
    background-image: url(../img/arrow-up-right-square.svg);
    background-size: 50%;
    background-repeat: no-repeat;
    content: "  ";
    width: 1em;
    height: 1em;
    position: absolute;
}
a.line-through {
    text-decoration: line-through red 2px !important;
}
a.button {
    text-decoration: none; 
}
#output {
    background: #eee;
    color: #333;
    overflow: auto;
    flex-grow: .25;
    flex-shrink: 3;
    font-family: monospace;

    padding: .5rem;
    
    h1 {
        font-size: large;
        margin: 0 0 .5rem;
    }

    summary {
        font-weight: bold
    }

    pre {
        border-bottom: 1px solid grey;
        padding-bottom: .25rem;
    }
}

.log {
    font-size: smaller;
    font-style: italic;
    color: #666;
    overflow-y: auto;

    li {
        display: block;
    }

    li.error {
        color: var(--error-fg);
    }

    li.error::before {
        content: "‼️ ";
    }

    li.warn {
        color: var(--warn-fg);
    }

    li:nth-child(2) {
        opacity: 90%;
    }

    li:nth-child(3) {
        opacity: 70%;
    }

    li:nth-child(n+4) {
        opacity: 50%;
    }
}

meter {
    width: 100%;
    height: 1.5rem;
    border-radius: .25rem;
    overflow: clip;
}

meter::-webkit-meter-bar {
    height: 1.25rem;
    background-color: whitesmoke;
    background-image: linear-gradient(#e6e6e6, #e6e6e6, #eeeeee 20%, #cccccc 45%, #cccccc 55%);
    border-radius: .25rem;
}

meter::-webkit-meter-optimum-value {
    background-image: linear-gradient(#ad7, #ad7, #cea 20%, #7a3 45%, #7a3 55%);
}

meter::-webkit-meter-suboptimum-value {
    background-image: linear-gradient(#fe7, #fe7, #ffc 20%, #db3 45%, #db3 55%);
}

meter::-webkit-meter-even-less-good-value {
    background-image: linear-gradient(#f77, #f77, #fcc 20%, #d44 45%, #d44 55%);
}

td img {
    max-height: 1rem;
}

td[data-type=int],
td[data-type=float],
th[data-type=int],
th[data-type=float] {
    text-align: right;
}

.login-page {
    margin: 1rem;
}

.login-form {
    max-width: 30em;
    min-width: 20em;
    margin: 1rem auto;
    padding: .5rem;
    border: .25rem outset var(--frame-border-focused);
    background-color: var(--color-light);
    text-align: center;
    height: auto;

    button {
        margin: .5rem auto;
        display: block;
        font-size: larger;
        width: 100%;
    }
}

borb-frame>* {
    width: 100%;
    height: 100%;
    background: #eed;
    overflow: auto;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        padding-top: 0em;
        margin-top: .25em;
        margin-bottom: .25em;
    }

    p {
        margin-block-start: .25em;
        margin-block-end: .25em;
    }
}

borb-sheet, table {
    border-collapse: collapse;
    .disabled {
        color: #888;
    }
    tr {
        vertical-align: middle;
        border-bottom: 1px dotted #8888;
    }

    td {
        border-left: 1px dotted #8888;
    }

    thead {
        position: sticky;
        top: 0;
        z-index: 100;
        background: #eee;
    }

    tbody tr:nth-child(even) {
        background: #fff8ff
    }

    tbody tr:nth-child(odd) {
        background: #f8ffff
    }

    tbody tr:hover {
        background: #ccffaa
    }

    tr.bold :nth-child(1),
    tr.bold :nth-child(2) {
        font-weight: bold;
    }

    th,
    td {
        padding: .125em;
    }

}

.ew-resizer {
    background-image: linear-gradient(90deg, #0000 0%, var(--resizer-bg) 40%, var(--resizer-bg) 60%, #0000 100%);
}

.ns-resizer {
    background-image: linear-gradient(#0000 0%, var(--resizer-bg) 40%, var(--resizer-bg) 60%, #0000 100%);
}

.ew-resizer:hover {
    background-image: linear-gradient(90deg, #fff0 40%, var(--resizer-bg-hover) 40%, var(--resizer-bg-hover) 60%, #fff0 60%);
}

.ns-resizer:hover {
    background-image: linear-gradient(#fff0 40%, var(--resizer-bg-hover) 40%, var(--resizer-bg-hover) 60%, #fff0 60%);
}

.ew-resizer {
    left: 100%;
    height: 100%;
    width: .6rem;
    margin: 0 auto;
    cursor: ew-resize;
}

.ns-resizer {
    bottom: 100%;
    width: 100%;
    height: .6rem;
    margin: -.30rem .25rem auto .25rem;
    cursor: ns-resize;
}

form,
.form {
    /*  display: flex; // !important;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: start;
    align-items: stretch;
    gap: .25rem .75rem;*/
    font-size: 1rem;

    input,
    .field {
        font-size: 1rem;
        border: .125rem groove ButtonBorder;
        border-radius: .25rem;
        padding-inline: .125rem;
        padding-block: .125rem;
    }

    &>label {
        flex-grow: 1;
    }

    &>.form-field,
    &>.form-control {
        .is-wide & {
            width: 47%;
        }

        .is-very-wide & {
            width: 30%;
        }

        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        flex-basis: max-content;
        flex-shrink: 1;
        min-height: 1.25rem;
        gap: .25rem
    }

    .form-field>input,
    .form-field>.field {
        flex-grow: 2
    }

    .form-control>button {
        flex-grow: 1;
        flex-shrink: 1;
    }

    //  .form-control button:disabled {
    //   display: none;
    //  }

    button {
        position: relative;
        margin-left: .25rem;
    }

    button.check-ok:not(.error)::before {
        content: "✔️"
    }

    button.check-unknown:not(.error)::before {
        content: "❓"
    }

    button.check-error::before {
        content: "❌"
    }

    button.error::before {
        content: "❌"
    }

    button[data-error]::after {
        content: attr(data-error);
        position: absolute;
        margin-left: 1rem;
        border: 0.125rem solid var(--color-3);
        background: white;
        padding: .05rem;
        right: 100%;
        width: max-content;
        top: 50%;
        transform: translateY(-50%);
        margin-right: .5rem;
        border-radius: .125rem;
        pointer-events: none;
        max-width: 200%;
        z-index: 99
    }
}

header {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    background: var(--color-light);
    min-height: 2.25rem;
    //padding: .25rem;

    div {
        padding: 0.05rem .25rem;
    }

    #page-home {
        text-align: left;
        background: var(--color-1);
        min-width: 33%;
        flex-grow: 1;

        span.logo {
            @include pixel-font;
            font-style: italic;
            margin-right: 2rem;
        }

        display: flex;
        flex-direction: row;
        justify-content: normal;
        align-items: center;
    }

    #course-info {
        text-align: center;
        background: var(--color-2);
        min-width: 33%;

        * {
            vertical-align: middle;
        }
    }

    #user-info {
        flex-grow: 1;
        text-align: right;
        background: var(--color-3);
        min-width: 33%;

        * {
            vertical-align: middle;
        }
    }

    #user-info .is-admin::before {
        content: "👩‍💻";
    }

    #user-info .name[title="teacher"]::after {
        content: " 🧑‍🏫";
    }

    #user-info .account-status {
        display: none;

        &>[data-account] {
            display: inline;

            &[data-is-active]::before {
                content: attr(data-account-short);
            }
        }

        &:hover>[data-account] {
            display: block;

            &::before {
                content: "❌"
            }

            &[data-is-active]::before {
                content: "✔️"
            }

            &::after {
                content: attr(data-account-name);
            }
        }
    }

    //data-account="canvas" data-account-name="Canvas" data-is-active=${self.canvas_account
}

.flashes {
    position: absolute;
    display: flex;
    flex-direction: column;
    //border: 1px solid black;
    z-index: 999;
    right: 0%;
    margin: 1rem;
    padding: .25rem;
    overflow: clip;
    opacity: 70%;

    &:hover {
        opacity: 100%;
    }

    // TODO: 
    .flash {
        margin: .25rem;
        transform-origin: top;
        transition-duration: 1s;
        transition-delay: 0s;
        transition-timing-function: ease-in;
        transition-property: all;
        display: flow-root;
        overflow: hidden;
        padding: 0;
        position: relative;
        min-width: 30em;
        max-height: fit-content; /* TODO: needed? */
        width: auto;


        /*    &:hover,
        &.new {
            transition: none;
            opacity: 100%;
        }*/

        right: 0%;

        .flash-inner {
            transition: 1s all;
            background: white;
            border: .25rem solid white;
            border-radius: .5rem;
            padding: .5rem;
            //  position:absolute;
            //  margin: -.5rem;


        }

        &.message .flash-inner {
            border-color: #{color.scale(green, $lightness:10%)};
            background-color: #{color.scale(green, $lightness:95%)}
        }

        &.warning .flash-inner {
            border-style: dotted;
            border-color: #{color.scale(orange, $lightness:10%)};
            background-color: #{color.scale(orange, $lightness:95%)}
        }

        &.error .flash-inner {
            border-style: dashed;
            border-color: #{color.scale(red, $lightness:10%)};
            background-color: #{color.scale(red, $lightness:95%)}
        }
        &.remove {
            opacity: 0%;
            z-index: 998;
        }
        @media(prefers-reduced-motion: no-preference) {
            &.remove {
                &:not(:last-child) {
                    max-height: 0 !important;
                }

                right: -100%;
                margin-top: 0;
                margin-bottom: 0;
            }

        }
    }
}

.flashes.left {
    left: 0%;

    .flash {
        left: 0%;
    }

    .flash.remove {
        left: -100%;
    }
}

.two-column {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 2rem;
    width:100%;
    flex-wrap: wrap;
    div {
        max-width: calc(max(40em,650px));
        min-width: 15em;
        flex-shrink: 1;
    }
}